body * {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  font-size: 1rem;
}

html,
body {
  font-size: 16px;
  height: 100%;
}

h1 {
  color: #497af2;
  font-size: 2.25rem;
  line-height: 48px;
  font-weight: normal;
}

h2 {
  color: black;
  font-size: 1.8rem;
  line-height: 1;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

h3 {
  opacity: 0.4;
  color: #373b4a;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: normal;
}

.ReactModal__Content {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  max-width: 975px;
  left: 50% !important;
  right: auto !important;
  width: 80% !important;
  transform: translate(-50%, 0);
  &[data-testid="crop-image"] {
    max-width: max-content !important;
    width: 100% !important;
  }
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.45) !important;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.2rem;
  }
}
.ReactModal__Overlay {
  z-index: 5;
}
.container50 {
  display: flex;
  & > * {
    flex: 1 1 auto;
    margin-right: 1rem;
  }
  & > :last-child {
    margin-right: 0;
  }
}
