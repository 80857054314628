.accordion__title {
  background: #497af2;
  color: white;
  height: 3em;
  position: relative;
  line-height: 3em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  padding-left: 1.25em;
  padding-right: 95px;
  overflow: hidden;
}

.accordion__title--closed {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
}

.accordion__title__text {
  display: contents;
  vertical-align: middle;
}

.accordion__title__open {
  background: url('./images/chevron-up.png');
  width: 17px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -5px;
  transition: 0.5s ease all;
}

.accordion__title__open--closed {
  transform: rotate(180deg);
}

.accordion__title__open--opened {
  transform: rotate(0);
}

.accordion__content {
  transition: all 0.5s ease;
  overflow: hidden;
}

.accordion__content--closed {
  max-height: 0;
  opacity: 0;
}

.accordion__content--opened {
  max-height: 80em;
  opacity: 1;
}

.accordion__content {
  border-left: 1px solid #e3e6f1;
  border-right: 1px solid #e3e6f1;
  border-bottom: 1px solid #e3e6f1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.22);
  margin-bottom: 2em;
}

.accordion__title__option {
  width: 0.8em;
  height: 0.8em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 2em;
  background: url('../images/white-handles.png');
  background-size: cover;
}
.accordion__title__edit{
  position: absolute;
  right: 4rem;
  top: 50%;
  transform: translate(0,-50%);
}
@media only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 1.25dppx) {
  .accordion__title__open {
    background: url('./images/chevron-up@2x.png');
    background-size: cover;
  }
}

@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
  .accordion__title__open {
    background: url('./images/chevron-up@3x.png');
    background-size: cover;
  }
}
